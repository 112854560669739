import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <title>letter</title>
    <g className="nc-icon-wrapper">
      <path
        data-color="color-2"
        d="M42,5H6a5,5,0,0,0-5,5v4a1,1,0,0,0,.521.878l22,12a1,1,0,0,0,.958,0l22-12A1,1,0,0,0,47,14V10A5,5,0,0,0,42,5Z"
      />
      <path d="M25.437,28.634a3,3,0,0,1-2.87,0L1.023,16.885,1,16.9V38a5,5,0,0,0,5,5H42a5,5,0,0,0,5-5V16.9l-.023-.014Z" />
    </g>
  </svg>
);
