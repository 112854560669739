import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <title>small up</title>
    <g className="nc-icon-wrapper">
      <polyline
        fill="none"
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        points="20,38 32,26 44,38 "
        strokeLinejoin="miter"
      />
    </g>
  </svg>
);
