import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Icon.css';

import UK from './svgs/uk';
import Germany from './svgs/germany';

import SmallDown from './svgs/small-down';
import SmallUp from './svgs/small-up';
import SmallRight from './svgs/small-right';
import SmallLeft from './svgs/small-left';

import Mail from './svgs/letter';
import Quote from './svgs/quote';
import Xing from './svgs/xing';
import Twitter from './svgs/logo-twitter';
import Facebook from './svgs/logo-facebook';
import Linkedin from './svgs/logo-linkedin';
import Whatsapp from './svgs/whatsapp';
import Phone from './svgs/phone';
import Download from './svgs/download';
import PDF from './svgs/pdf';

import IconNamePropType from './IconNamePropType';

const Icon = ({ iconName, size, strokeWidth }) => {
  const classes = [s.icon];

  if (size === 0) {
    classes.push(s.icon_full);
  } else if (size === 1.25) {
    classes.push(s.icon_1_25);
  } else if (size === 1.5) {
    classes.push(s.icon_1_5);
  } else if (size === 2) {
    classes.push(s.icon_2);
  } else if (size === 3) {
    classes.push(s.icon_3);
  } else if (size === 4) {
    classes.push(s.icon_4);
  } else if (size === 5) {
    classes.push(s.icon_5);
  } else if (size === 6) {
    classes.push(s.icon_6);
  } else if (size === 7) {
    classes.push(s.icon_7);
  }

  if (strokeWidth === 3) {
    classes.push(s.stroke_width_3);
  } else if (strokeWidth === 4) {
    classes.push(s.stroke_width_4);
  }
  let SVG = null;
  switch (iconName) {
    case 'mail':
      SVG = <Mail />;
      break;
    case 'xing':
      SVG = <Xing />;
      break;
    case 'pdf':
      SVG = <PDF />;
      break;
    case 'download':
      SVG = <Download />;
      break;
    case 'whatsapp':
      SVG = <Whatsapp />;
      break;
    case 'facebook':
      SVG = <Facebook />;
      break;
    case 'twitter':
      SVG = <Twitter />;
      break;
    case 'linkedin':
      SVG = <Linkedin />;
      break;
    case 'phone':
      SVG = <Phone />;
      break;
    case 'quote':
      SVG = <Quote />;
      break;
    case 'small-down':
      SVG = <SmallDown />;
      break;
    case 'small-up':
      SVG = <SmallUp />;
      break;
    case 'small-left':
      SVG = <SmallLeft />;
      break;
    case 'small-right':
      SVG = <SmallRight />;
      break;
    /* Countries */
    case 'uk':
      SVG = <UK />;
      break;
    case 'de':
      SVG = <Germany />;
      break;
    default:
      SVG = null;
      break;
  }

  return <span className={classes.join(' ')}>{SVG}</span>;
};

Icon.propTypes = {
  iconName: PropTypes.oneOf(IconNamePropType).isRequired,
  size: PropTypes.oneOf([0, 1, 1.25, 1.5, 2, 3, 4, 5, 6, 7]),
  strokeWidth: PropTypes.oneOf([2, 3, 4]),
};

Icon.defaultProps = {
  size: 1,
  strokeWidth: 2,
};

export default withStyles(s)(Icon);
