import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <g className="nc-icon-wrapper">
      <path
        id="White_4_"
        d="M46,48c1.10457,0,2-0.89543,2-2V2c0-1.10457-0.89543-2-2-2H2C0.89543,0,0,0.89543,0,2v44 c0,1.10457,0.89543,2,2,2H46z"
      />{' '}
      <path
        id="White_2_"
        fill="#FFFFFF"
        d="M25.51917,48V29H19v-7l6.51917,0v-5.17462c0-6.19977,3.78661-9.57566,9.31723-9.57566 c2.64922,0,4.92609,0.19724,5.58964,0.2854v6.47912l-3.83578,0.00174C33.5824,14.01598,33,15.44527,33,17.54265V22h7l-1,7h-6v19 H25.51917z"
      />
    </g>
  </svg>
);
