export default [
  'mail',
  'xing',
  'uk',
  'de',
  'small-left',
  'small-right',
  'small-up',
  'small-down',
  'quote',
  'facebook',
  'twitter',
  'phone',
  'linkedin',
  'pdf',
  'download',
];
