import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <title>ic_file_download_48px</title>
    <g className="nc-icon-wrapper">
      <path d="M38 18h-8V6H18v12h-8l14 14 14-14zM10 36v4h28v-4H10z" />
    </g>
  </svg>
);
