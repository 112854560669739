import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <title>pdf</title>
    <g className="nc-icon-wrapper">
      <path d="M5,43v3c0,0.552,0.447,1,1,1h36c0.553,0,1-0.448,1-1v-3H5z" />{' '}
      <path d="M43,21v-7H31c-0.553,0-1-0.448-1-1V1H6C5.447,1,5,1.448,5,2v19H43z" />{' '}
      <polygon data-color="color-2" points="32,2.586 32,12 41.414,12 " />{' '}
      <path
        data-color="color-2"
        d="M17.042,29.861h-0.548v1.805h0.387c0.319,0,0.572-0.09,0.76-0.27 c0.188-0.179,0.282-0.426,0.282-0.74C17.922,30.126,17.629,29.861,17.042,29.861z"
      />{' '}
      <path
        data-color="color-2"
        d="M24.062,29.861h-0.618v4.398h0.473c0.645,0,1.117-0.188,1.418-0.562s0.451-0.946,0.451-1.716 c0-0.72-0.142-1.253-0.427-1.601C25.074,30.034,24.642,29.861,24.062,29.861z"
      />{' '}
      <path
        data-color="color-2"
        d="M46,23H2c-0.552,0-1,0.448-1,1v16c0,0.552,0.448,1,1,1h44c0.552,0,1-0.448,1-1V24 C47,23.448,46.552,23,46,23z M19.295,32.678c-0.518,0.479-1.252,0.717-2.205,0.717h-0.596V36h-2.122v-7.853h2.718 c0.992,0,1.737,0.217,2.234,0.649c0.498,0.434,0.747,1.05,0.747,1.848C20.071,31.522,19.812,32.2,19.295,32.678z M26.905,34.937 C26.184,35.646,25.17,36,23.863,36h-2.541v-7.853h2.718c1.26,0,2.233,0.322,2.919,0.967c0.686,0.645,1.029,1.575,1.029,2.793 C27.988,33.218,27.627,34.228,26.905,34.937z M34.036,29.85h-2.551v1.499h2.353v1.702h-2.353V36h-2.089v-7.853h4.641V29.85z"
      />
    </g>
  </svg>
);
